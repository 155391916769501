import React from 'react';
import { observer } from 'mobx-react'
import Select from 'react-select'
import CKEditor from 'ckeditor4-react'
import store from '../../store'
import { API, TOOLBAR_ENTITY, BROWSE_SERVER, UPLOAD_SERVER, SELECT_STYLES, SELECT_THEME_PRIMARY, TAXONOMIES, OPTIONS, PUBLISHED } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import { isObjectEmpty } from '../../utility/helpers'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import NewsMentions from './NewsMentions'

// apis
import newsApi from '../../apis/newsApi';
import { getTaxonomyData, getLocations, getNewsTagsList, getLocationsNews, getNewsMention, getTagsNews, getIndustries, getMultiIndustry } from '../../utility/commonApis';

class ArticleForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                title: '',
                body: '',
                image_id: '',
                image_credit: '',
                category: '',
                author: '',
                source: '',
                is_published: '',
                is_featured: '',
                code: '',
                sponsor_name: '',
                sponsor_description: '',
                sponsor_logo_id: '',
                preview_image: '',
            },
            body_image: '',
            body_imagePath: '',
            image_idError: false,
            image_idSuccess: false,
            image_idUpload: false,
            image_idMsg: '',
            image_idFile: '',
            locations: [],
            tags: [],
            categories: [],
            industries: [],
            industry: [], //selectedIndustries
            editParams: [],
            multiFields: {
                'news-tag': [],
                'news-mention': [],
                location: [],
                industry: [],
                'news-category': []
            },
            'news-tagValue': '',
            'news-mentionValue': '',
            locationValue: '',
            'news-mentionValue': '',
            'news-tag': [],
            'news-mention': [],
            'news-category': [],
            location: [],
            fileFields: ['image_id'],
            validationFields: ['title', 'body', 'image_id', 'preview_image'],

            // error
            sponsor_logo_idMsg: '',
            sponsor_logo_idError: false,
            sponsor_logo_idSuccess: false,
            sponsor_logo_idUpload: false,

            preview_imageError: false,
            preview_imageSuccess: false,
            preview_imageUpload: false,
            preview_imageMsg: '',
        }

        this.parameters = {
            title: '',
            body: '',
            image_id: '',
            image_credit: '',
            category: '',
            author: '',
            source: '',
            is_published: '',
            is_featured: '',
            code: '',
            sponsor_name: '',
            sponsor_description: '',
            sponsor_logo_id: '',
            preview_image: '',
        }
    }

    componentDidMount() {
        store.apis = [];
        if (store.isEdit && store.currentId) {
            const paramFields = ['id', 'code', 'title', 'body', 'category', 'industry_data', 'category_data', 'image_data', 'image_credit', 'author', 'source', 'country_data', 'is_published', 'is_featured', 'sponsor_name', 'sponsor_description', 'sponsor_logo_data', 'preview_image_data'];
            const fields = prepareSelectParam(paramFields);

            fetch(`${API}/news/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'image_id') {
                            obj[key] = !isObjectEmpty(data.data.image_data) ? data.data.image_data.name : ''
                        }
                        else if (key === 'category') {
                            obj[key] = !isObjectEmpty(data.data.category_data) ? { 'value': data.data.category, 'label': data.data.category_data.name } : ''
                        }
                        else if (key === 'is_published') {
                            obj[key] = data.data[key] === 0 ? { value: 0, label: 'Draft' } : data.data[key] === 1 ? { value: 1, label: 'Published' } : data.data[key] === 2 ? { value: 2, label: 'UnPublished' } : ''
                        }
                        else if (key === 'is_featured') {
                            obj[key] = data.data[key] !== null ? data.data[key] === 0 ? { value: 0, label: 'No' } : { value: 1, label: 'Yes' } : ''
                        }
                        else if (key === 'sponsor_logo_id') {
                            obj[key] = !isObjectEmpty(data.data.sponsor_logo_data) ? data.data.sponsor_logo_data.name : '';
                        }
                        else if (key === 'preview_image') {
                            obj[key] = !isObjectEmpty(data.data.preview_image_data) ? data.data.preview_image_data.name : ''
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })
                    this.state.formFields = obj

                    // if(data && data.data && data.data.industry_data){
                    //     this.state.industry = data.data.industry_data
                    // }

                    this.setState(this.state)
                })

            getNewsMention(`news/${store.currentId}`)

            getTagsNews(`news/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ 'news-tag': res })
                }
            })

            getLocationsNews(`news/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ location: res })
                }
            })

            getMultiIndustry(`news/${store.currentId}`, ['id', 'industry_id']).then(res => {
                if (res.length > 0) {
                    this.setState({ industry: res })
                }
            })

            newsApi.getNewsCategories(store.currentId).then(
                response => {
                    if (response.length > 0) {
                        let categoriesArr = [];

                        response.map(item => {
                            if (!isObjectEmpty(item.category_data)) {
                                categoriesArr.push({ id: item.id, name: item.category_data.name });
                            }
                        });

                        this.setState({ 'news-category': categoriesArr });
                    }
                }
            )
        }

        getTaxonomyData(TAXONOMIES.newsCategory).then(res => {
            this.setState({ categories: res })
        })

        getLocations().then(res => {
            this.setState({ locations: res })
        })

        getNewsTagsList().then(res => {
            this.setState({ tags: res })
        })

        getIndustries().then(industries => {
            this.setState({ industries })
        })

    }

    tempImageUpload1 = async (loader) => {
        const formData = new FormData()
        formData.append('typeOption', 'upload_image')
        formData.append('file', loader.file)

        let response = await fetch(`${API}/file`, {
            method: 'POST',
            headers: {
                'Authorization': store.token,
            },
            body: formData
        })
        let data = response.json()
        if (data.status === 200 || data.status === 201) {
            return data.data.path
        }
        else {
            return null
        }
    }

    renderErrors = () => {
        let { 
            titleEmpty,
            body_imageMsg,
            body_imageError,
            bodyEmpty,
            image_idMsg,
            image_idEmpty,
            image_idError,
            image_idUpload,
            preview_imageError,
            preview_imageMsg,
            preview_imageUpload,
        } = this.state

        let errors = []

        if (titleEmpty)
            errors.push(<div className='error'>News Title cannot be empty</div>)
        if (body_imageMsg)
            errors.push(<div className='error'>{this.state.body_imageMsg}</div>)
        if (body_imageError || preview_imageError)
            errors.push(<div className='error'>Upload failed!.</div>)
        if (bodyEmpty)
            errors.push(<div className='error'>News body cannot be empty</div>)
        if (image_idMsg && image_idMsg.length > 0)
            errors.push(<div className='error'>{this.state.image_idMsg}</div>)
        if (image_idEmpty)
            errors.push(<div className='error'>Image cannot be empty</div>)
        if (image_idError)
            errors.push(<div className='error'>Upload failed!.</div>)
        if (image_idUpload || preview_imageUpload)
            errors.push(<div className='error'>Image is chosen but not uploaded.</div>)
        if (preview_imageMsg && preview_imageMsg.length > 0)
            errors.push(<div className='error'>{preview_imageMsg}</div>)

        return errors
    }

    render() {
        let { industries, industry } = this.state
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT NEWS</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD NEWS</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Title <span className='required'>*</span></label>
                                            <input type="text" className="form-control" value={this.state.formFields.title} name='title' onChange={this.handleInputChange} />
                                            {this.state.titleEmpty && <div className='error'>News Title cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Body Image upload</label>
                                            <div className="input-group mb-3">
                                                <div className={"custom-file"}>
                                                    <div className="form-filename-holder">{this.state.body_image === '' ? 'Choose a File' : this.state.body_image}</div>
                                                    <input type='file' className="custom-file-input" name='body_image' onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.tempImageUpload('logo', 'body_image', this.state.body_imageFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.body_imageMsg && <div className='error'>{this.state.body_imageMsg}</div>}
                                            {this.state.body_imagePath !== '' && <label >Image Url: {this.state.body_imagePath}</label>}
                                            {this.state.body_imageError && <div className='error'>Upload failed!.</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Body <span className='required'>*</span></label>
                                            <CKEditor
                                                name='body'
                                                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                                                onInit={(editor) => console.log(editor)}
                                                config={{
                                                    height: 400,
                                                    allowedContent: true,
                                                    toolbar: TOOLBAR_ENTITY,
                                                    filebrowserImageBrowseUrl: BROWSE_SERVER,
                                                    filebrowserImageUploadUrl: UPLOAD_SERVER
                                                }}
                                                data={this.state.formFields.body}
                                                onChange={(ev) => this.handleRichEditorInputChange(ev, 'body')}
                                            />
                                            {this.state.bodyEmpty && <div className='error'>News body cannot be empty</div>}
                                        </div>
                                    </div>

                                    {/*<div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Body <span className='required'>*</span></label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    height: 400,
                                                    plugins: [Font],
                                                    fontFamily: ['default',
                                                        'Arial, Helvetica, sans-serif',
                                                        'Courier New, Courier, monospace',
                                                        'Georgia, serif',
                                                        'Lucida Sans Unicode, Lucida Grande, sans-serif',
                                                        'Tahoma, Geneva, sans-serif',
                                                        'Times New Roman, Times, serif',
                                                        'Trebuchet MS, Helvetica, sans-serif',
                                                        'Verdana, Geneva, sans-serif']
                                                    //toolbar: TOOLBAR,
                                                    //filebrowserImageBrowseUrl: BROWSE_SERVER,
                                                    //filebrowserImageUploadUrl: UPLOAD_SERVER
                                                }}
                                                data={this.state.formFields.body}
                                                onChange={(ev, editor) => { this.state.formFields.body = editor.getData(); this.setState(this.state) }}
                                            />
                                            {this.state.bodyEmpty && <div className='error'>News body cannot be empty</div>}
                                        </div>
                                        </div>*/}

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Image<span className='required'> *</span></label>
                                            <p className="form-text text-muted">
                                                (The main image shown in the directory + landing page mobile view)
                                            </p>
                                            <div className="input-group mb-3">
                                                <div className={this.state.image_idEmpty ? "custom-file input-error" : "custom-file"}>
                                                    <div className="form-filename-holder">{this.state.formFields.image_id === '' ? 'Choose a File' : this.state.formFields.image_id}</div>
                                                    <input type='file' className="custom-file-input" name="image_id" onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('image', 'image_id', this.state.image_idFile)}>Upload</span>
                                                </div>
                                            </div>
                                            {this.state.image_idMsg.length > 0 && <div className='error'>{this.state.image_idMsg}</div>}
                                            {this.state.image_idEmpty && <div className='error'>Image cannot be empty</div>}
                                            {this.state.image_idError && <div className='error'>Upload failed!.</div>}
                                            {this.state.image_idSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.image_idUpload && <div className='error'>Image is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Long Image<span className='required'> *</span></label>
                                            <p className="form-text text-muted">
                                                (The long image shown in the landing page desktop view)
                                            </p>
                                            <div className="input-group mb-3">
                                                <div className={this.state.preview_imageEmpty ? "custom-file input-error" : "custom-file"}>
                                                    <div className="form-filename-holder">{this.state.formFields.preview_image === '' ? 'Choose a File' : this.state.formFields.preview_image}</div>
                                                    <input type='file' className="custom-file-input" name='preview_image' onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('preview-image', 'preview_image_id', this.state.preview_imageFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.preview_imageMsg.length > 0 && <div className='error'>{this.state.preview_imageMsg}</div>}
                                            {this.state.preview_imageError && <div className='error'>Upload failed!</div>}
                                            {this.state.preview_imageSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.preview_imageUpload && <div className='error'>Preview image is chosen but not uploaded</div>}
                                            {this.state.preview_imageEmpty && <div className='error'>Long image cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Image Credit</label>
                                            <input type="text"
                                                className={"form-control"}
                                                value={this.state.formFields.image_credit}
                                                name='image_credit'
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Locations</label>
                                            <Select
                                                placeholder='Select location'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.locationValue}
                                                onChange={option => this.handleMultiSelectChange(option, 'location_id', 'location')}
                                                options={this.state.locations}
                                            />
                                            {this.state.location.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.location.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'location')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Tags</label>
                                            <Select
                                                placeholder='Select tag'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state['news-tagValue']}
                                                onChange={option => this.handleMultiSelectChange(option, 'tag_id', 'news-tag')}
                                                options={this.state.tags}
                                            />
                                            {this.state['news-tag'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['news-tag'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'news-tag')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Industries</label>
                                            <Select
                                                placeholder='Select Industry'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state['industryValue']}
                                                onChange={option => this.handleMultiSelectChange(option, 'industry_id', 'industry')}
                                                options={industries}
                                            />
                                            {industry.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {industry.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'industry')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Category</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state['news-categoryValue']}
                                                onChange={option => this.handleMultiSelectChange(option, 'category_id', 'news-category')}
                                                options={this.state.categories}
                                            />
                                            {this.state['news-category'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['news-category'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'news-category')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Author</label>
                                            <input type="text" className="form-control" value={this.state.formFields.author} name='author' onChange={this.handleInputChange} />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Source</label>
                                            <input type="text" className="form-control" value={this.state.formFields.source} name='source' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Published</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.is_published}
                                                onChange={(option) => this.handleSelectChange(option, 'is_published')}
                                                options={PUBLISHED}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Featured</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.is_featured}
                                                onChange={(option) => this.handleSelectChange(option, 'is_featured')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >URL</label>
                                            <input type="text"
                                                className={"form-control"}
                                                value={this.state.formFields.code}
                                                name='code'
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Sponsor Name</label>
                                            <input type="text"
                                                className={"form-control"}
                                                value={this.state.formFields.sponsor_name}
                                                name='sponsor_name'
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Sponsor Logo</label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">
                                                        {this.state.formFields.sponsor_logo_id === '' ? 'Choose a File' : this.state.formFields.sponsor_logo_id}
                                                    </div>
                                                    <input 
                                                        className="custom-file-input" 
                                                        type='file' 
                                                        name='sponsor_logo_id' 
                                                        onChange={this.handleImageUpload} 
                                                    />
                                                </div>
                                                <div className="input-group-append">
                                                    <span 
                                                        className="input-group-text" 
                                                        onClick={() => this.addFileUpload('sponsor-logo', 'sponsor_logo_id', this.state.sponsor_logo_idFile)}
                                                    >
                                                            Upload
                                                    </span>
                                                </div>
                                            </div>
                                            {this.state.sponsor_logo_idMsg.length > 0 && <div className='error'>{this.state.sponsor_logo_idMsg}</div>}
                                            {this.state.sponsor_logo_idError && <div className='error'>Upload failed!</div>}
                                            {this.state.sponsor_logo_idSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.sponsor_logo_idUpload && <div className='error'>Sponsor Logo is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Sponsor Description</label>
                                            <textarea
                                                className={"form-control"}
                                                name='sponsor_description'
                                                row="5"
                                                value={this.state.formFields.sponsor_description}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <NewsMentions body={this.state.formFields.body} />

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate('news')}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('news')}>Add News</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(ArticleForm)
