import React from 'react'
import { observer } from 'mobx-react'
import CKEditor from 'ckeditor4-react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import store from '../../store'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, TOOLBAR, TOOLBAR_ENTITY, BROWSE_SERVER, UPLOAD_SERVER, API, LOCATIONS, PUBLISHED, TAXONOMIES, LANGUAGES, OPTIONS, ALLOWED_QUERY_OPERATOR, RESEARCH_DOWNLOAD_FORM_TYPES } from '../../constant'
import { getTaxonomyData, getResearchTagsList, getLocations, getTagsResearch, getResearchFiles, getIndustries, getMultiIndustry } from '../../utility/commonApis'
import { prepareSelectParam } from '../../utility/queryParams'
import { isObjectEmpty } from '../../utility/helpers'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import researchApi from '../../apis/researchApi'
import { RESEARCH_DOWNLOAD_FORM_TYPE_OPTIONS } from '../../utility/constants/selectOptions'

class ResearchForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                short_description: '',
                full_description: '',
                body: '',
                image_id: '',
                preview_1: '',
                preview_2: '',
                preview_3: '',
                preview_4: '',
                category_id: '',
                type_id: '',
                sample_id: '',
                location_id: '',
                price: '',
                is_published: '',
                preview_image: '',
                code: '',
                lang: '',
                sponsor_logo_id: '',
                is_featured: '',
                promoted_research_id: '',
                download_form_type: '',
                table_of_contents: '',
                table_of_contents_promoted: '',
            },
            logoFile: '',
            desc_image: '',
            body_image: '',
            body_imagePath: '',
            desc_imagePath: '',
            image_idError: false,
            image_idSuccess: false,
            image_idUpload: false,
            image_idMsg: '',
            sample_idError: false,
            sample_idSuccess: false,
            sample_idUpload: false,
            sample_idMsg: '',
            preview_imageError: false,
            preview_imageSuccess: false,
            preview_imageUpload: false,
            preview_imageMsg: '',
            sponsor_logo_idError: false,
            sponsor_logo_idSuccess: false,
            sponsor_logo_idUpload: false,
            sponsor_logo_idMsg: '',
            locations: [],
            image_idFile: '',
            sample_idFile: '',
            sponsor_logo_idFile: '',
            types: [{ label: 'Sponsored', value: 2 }, { label: 'Paid', value: 1 }, { label: 'Free', value: 0 }],
            categories: [],
            researches: [],
            tags: [],
            industries: [],
            editParams: [],
            multiFields: {
                file: [],
                tag: [],
                industry: [],
            },
            tagValue: '',
            fileValue: '',
            industryValue: '',
            tag: [],
            file: [],
            fileFields: [],
            industry: [],
            validationFields: ['name', 'image_id', 'type_id', 'lang', 'preview_image'],
        }
        this.parameters = {
            name: '',
            short_description: '',
            full_description: '',
            body: '',
            image_id: '',
            preview_1: '',
            preview_2: '',
            preview_3: '',
            preview_4: '',
            category_id: '',
            type_id: '',
            sample_id: '',
            location_id: '',
            price: '',
            is_published: '',
            preview_image: '',
            code: '',
            lang: '',
            sponsor_logo_id: '',
            is_featured: '',
            promoted_research_id: '',
            table_of_contents: '',
            table_of_contents_promoted: '',
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            const paramFields = ['id', 'code', 'name', 'short_description', 'full_description', 'body', 'preview_1', 'preview_2', 'preview_3', 'preview_4', 'category_id', 'category_data', 'type_id', 'type_data', 'location_id', 'location_data', 'sample_data', 'image_data', 'price', 'is_published', 'tag_data', 'preview_image_data', 'sponsor_logo_data', 'lang', 'is_featured', 'promoted_research_data', 'download_form_type', 'table_of_contents', 'table_of_contents_promoted'];

            const fields = prepareSelectParam(paramFields);

            fetch(`${API}/research/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    this.state.tag = data.data.tag_data !== '' ? data.data.tag_data : []
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'image_id') {
                            obj[key] = !isObjectEmpty(data.data.image_data) ? data.data.image_data.name : ''
                        }
                        else if (key === 'preview_image') {
                            obj[key] = !isObjectEmpty(data.data.preview_image_data) ? data.data.preview_image_data.name : ''
                        }
                        else if (key === 'sample_id') {
                            obj[key] = !isObjectEmpty(data.data.sample_data) ? data.data.sample_data.name : ''
                        }
                        else if (key === 'type_id') {
                            obj[key] = data.data.type_id !== null ? { 'value': data.data.type_id, 'label': data.data.type_id === 0 ? 'Free' : data.data.type_id === 1 ? 'Paid' : 'Sponsored' } : ''
                        }
                        else if (key === 'location_id') {
                            obj[key] = data.data.location_data !== null ? { 'value': data.data.location_id, 'label': data.data.location_data.name } : ''
                        }
                        else if (key === 'category_id') {
                            obj[key] = data.data.category_data !== null ? { 'value': data.data.category_data.id, 'label': data.data.category_data.name } : ''
                        }
                        else if (key === 'is_published') {
                            obj[key] = data.data[key] === 0 ? { value: 0, label: 'Draft' } : data.data[key] === 1 ? { value: 1, label: 'Published' } : data.data[key] === 2 ? { value: 2, label: 'UnPublished' } : ''

                        } else if (key === 'sponsor_logo_id') {
                            obj[key] = !isObjectEmpty(data.data.sponsor_logo_data) ? data.data.sponsor_logo_data.name : ''

                        } else if (key === 'lang') {
                            obj[key] = data.data.lang ? { 'value': data.data.lang, 'label': data.data.lang === 'ar' ? 'Arabic' : 'English' } : ''
                        } else if (key === 'is_featured') {
                            obj[key] = data.data[key] !== null ? data.data[key] === 0 ? { value: 0, label: 'No' } : { value: 1, label: 'Yes' } : ''
                        } else if (key === 'promoted_research_id') {
                            obj[key] = data.data.promoted_research_data !== null ? { 'value': data.data.promoted_research_data.id, 'label': data.data.promoted_research_data.name } : ''
                        } else if (key === 'download_form_type') {
                            obj[key] = data.data.download_form_type !== null ? { value: data.data.download_form_type, 'label': RESEARCH_DOWNLOAD_FORM_TYPES[data.data.download_form_type] } : '';
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })
                    this.state.formFields = obj
                    this.setState(this.state)

                })
                .catch(err => {
                    console.log(err)
                })

            getTagsResearch(`research/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ tag: res })
                }
            })

            getResearchFiles(`research/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ file: res })
                }
            })

            getMultiIndustry(`research/${store.currentId}`).then((res) => {
                if (res.length > 0) {
                    this.setState({ industry: res });
                }
            })
        }

        getTaxonomyData(TAXONOMIES.researchCategory).then(res => {
            this.setState({ categories: res })
        })

        getResearchTagsList().then(res => {
            this.setState({ tags: res })
        })

        getLocations().then(res => {
            this.setState({ locations: res })
        })

        getIndustries().then((res) => {
            this.setState({ industries: res });
        })

    }

    getResearch = async (searchValue) => {
        let tempList = []
        let fields = ['id', 'name']
        let filters = [{ key: 'name', value: [searchValue], op: ALLOWED_QUERY_OPERATOR.substring }]

        await researchApi.getResearchData(fields, filters).then(response => {
            if (response && response.records) {

                response.records.map(item => tempList.push({ value: item.id, label: item.name }))

                this.setState({ researches: tempList })
            }
        })
    }

    loadPromotedResearches = async (searchValue) => {

        await this.getResearch(searchValue)

        return this.state.researches;

    }

    renderErrors = () => {
        let {
            nameEmpty,
            desc_imageError,
            body_imageError,
            sample_idError,
            image_idError,
            preview_imageError,
            sponsor_logo_idError,
            preview_imageMsg,
            research_fileMsg,
            sponsor_logo_idMsg,
            sample_idMsg,
            sample_idUpload,
            image_idUpload,
            preview_imageUpload,
            sponsor_logo_idUpload,
            image_idMsg,
            image_idEmpty,
            preview_imageEmpty,
            langEmpty,
            type_idEmpty
        } = this.state

        let errors = []

        if (nameEmpty)
            errors.push(<div className='error'>Title cannot be empty</div>)
        if (desc_imageError || body_imageError || sample_idError || image_idError || preview_imageError || sponsor_logo_idError)
            errors.push(<div className='error'>Upload failed!.</div>)
        if (preview_imageMsg && preview_imageMsg.length > 0)
            errors.push(<div className='error'>{preview_imageMsg}</div>)
        if (research_fileMsg && research_fileMsg.length > 0)
            errors.push(<div className='error'>{research_fileMsg}</div>)
        if (sponsor_logo_idMsg && sponsor_logo_idMsg.length > 0)
            errors.push(<div className='error'>{sponsor_logo_idMsg}</div>)
        if (sample_idMsg && sample_idMsg.length > 0)
            errors.push(<div className='error'>{sample_idMsg}</div>)
        if (sample_idUpload || image_idUpload || preview_imageUpload || sponsor_logo_idUpload)
            errors.push(<div className='error'>File is chosen but not uploaded.</div>)
        if (image_idEmpty || image_idMsg)
            errors.push(<div className='error'>Image cannot be empty</div>)
        if (preview_imageEmpty || preview_imageMsg)
            errors.push(<div className='error'>Long image cannot be empty</div>)
        if (type_idEmpty)
            errors.push(<div className='error'>Research type cannot be empty</div>)
        if (langEmpty)
            errors.push(<div className='error'>Language cannot be empty</div>)

        return errors
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT RESEARCH</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD RESEARCH</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Title <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.name}
                                                name='name'
                                                onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Title cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Short Description</label>
                                            <textarea className="form-control" rows='5' value={this.state.formFields.short_description} name='short_description' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Full description Image upload</label>
                                            <div className="input-group mb-3">
                                                <div className={"custom-file"}>
                                                    <div className="form-filename-holder">{this.state.desc_image === '' ? 'Choose a File' : this.state.desc_image}</div>
                                                    <input type='file' className="custom-file-input" name='desc_image' onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.tempImageUpload('logo', 'desc_image', this.state.desc_imageFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.desc_imagePath !== '' && <label >Image Url: {this.state.desc_imagePath}</label>}
                                            {this.state.desc_imageError && <div className='error'>Upload failed!.</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Full Description</label>
                                            <CKEditor
                                                config={{
                                                    height: 150,
                                                    allowedContent: true,
                                                    toolbar: TOOLBAR,
                                                    filebrowserImageBrowseUrl: BROWSE_SERVER,
                                                    filebrowserImageUploadUrl: UPLOAD_SERVER
                                                }}
                                                data={this.state.formFields.full_description}
                                                onChange={(ev) => this.handleRichEditorInputChange(ev, 'full_description')}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Body Image upload</label>
                                            <div className="input-group mb-3">
                                                <div className={"custom-file"}>
                                                    <div className="form-filename-holder">{this.state.body_image === '' ? 'Choose a File' : this.state.body_image}</div>
                                                    <input type='file' className="custom-file-input" name='body_image' onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.tempImageUpload('logo', 'body_image', this.state.body_imageFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.body_imagePath !== '' && <label >Image Url: {this.state.body_imagePath}</label>}
                                            {this.state.body_imageError && <div className='error'>Upload failed!.</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Body</label>
                                            <CKEditor
                                                config={{
                                                    height: 400,
                                                    allowedContent: true,
                                                    toolbar: TOOLBAR_ENTITY,
                                                    filebrowserImageBrowseUrl: BROWSE_SERVER,
                                                    filebrowserImageUploadUrl: UPLOAD_SERVER
                                                }}
                                                data={this.state.formFields.body}
                                                onChange={(ev) => this.handleRichEditorInputChange(ev, 'body')}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Preview Block1</label>
                                            <textarea className="form-control" rows='5' value={this.state.formFields.preview_1} name='preview_1' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Preview Block2</label>
                                            <textarea className="form-control" rows='5' value={this.state.formFields.preview_2} name='preview_2' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Preview Block3</label>
                                            <textarea className="form-control" rows='5' value={this.state.formFields.preview_3} name='preview_3' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Preview Block4</label>
                                            <textarea className="form-control" rows='5' value={this.state.formFields.preview_4} name='preview_4' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Sample</label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.sample_id === '' ? 'Choose a File' : this.state.formFields.sample_id}</div>
                                                    <input type='file' className="custom-file-input" name='sample_id' onChange={this.handleFileUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addSampleUpload(this.state.sample_idFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.sample_idMsg.length > 0 && <div className='error'>{this.state.sample_idMsg}</div>}
                                            {this.state.sample_idError && <div className='error'>Upload failed!.</div>}
                                            {this.state.sample_idSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.sample_idUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Image<span className='required'> *</span></label>
                                            <p className="form-text text-muted">
                                                (The main image shown in the directory + landing page mobile view)
                                            </p>
                                            <div className="input-group mb-3">
                                                <div className={this.state.image_idEmpty ? "custom-file input-error" : "custom-file"}>
                                                    <div className="form-filename-holder">{this.state.formFields.image_id === '' ? 'Choose a File' : this.state.formFields.image_id}</div>
                                                    <input type='file' className="custom-file-input" name='image_id' onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('image', 'image_id', this.state.image_idFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.image_idMsg.length > 0 && <div className='error'>{this.state.image_idMsg}</div>}
                                            {this.state.image_idEmpty && <div className='error'>Image cannot be empty</div>}
                                            {this.state.image_idError && <div className='error'>Upload failed!</div>}
                                            {this.state.image_idSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.image_idUpload && <div className='error'>Image is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Long Image<span className='required'> *</span></label>
                                            <p className="form-text text-muted">
                                                (The long image shown in the landing page desktop view)
                                            </p>
                                            <div className="input-group mb-3">
                                                <div className={this.state.preview_imageEmpty ? "custom-file input-error" : "custom-file"}>
                                                    <div className="form-filename-holder">{this.state.formFields.preview_image === '' ? 'Choose a File' : this.state.formFields.preview_image}</div>
                                                    <input type='file' className="custom-file-input" name='preview_image' onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('preview-image', 'preview_image_id', this.state.preview_imageFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.preview_imageMsg.length > 0 && <div className='error'>{this.state.preview_imageMsg}</div>}
                                            {this.state.preview_imageError && <div className='error'>Upload failed!</div>}
                                            {this.state.preview_imageSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.preview_imageUpload && <div className='error'>Preview image is chosen but not uploaded</div>}
                                            {this.state.preview_imageEmpty && <div className='error'>Long image cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Research Category</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.development_stageEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.category_id}
                                                onChange={(option) => this.handleSelectChange(option, 'category_id')}
                                                options={this.state.categories}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Research Files</label>
                                            <div className="custom-file" style={{ borderRadius: 5 }}>
                                                <div className="form-filename-holder">{this.state.fileValue === '' ? 'Choose a File' : this.state.fileValue}</div>
                                                <input type='file' className="custom-file-input" name='research_file' onChange={(ev) => this.handleMultiFileUpload(ev, 'file')} multiple />
                                            </div>
                                            {this.state.file.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.file.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'file')}></i></li>
                                                    })}
                                                </div>
                                            }
                                            {this.state.research_fileMsg && <div className='error'>{this.state.research_fileMsg}</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label >Tags</label>
                                            <Select
                                                placeholder='Select pages'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.tagValue}
                                                onChange={option => this.handleMultiSelectChange(option, 'tag_id', 'tag')}
                                                options={this.state.tags}
                                            />
                                            {this.state.tag.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.tag.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'tag')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label>Industry</label>
                                            <Select
                                                placeholder='Please select'
                                                styles={SELECT_STYLES}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY,
                                                    },
                                                })}
                                                value={this.state.industryValue}
                                                onChange={(option) =>
                                                    this.handleMultiSelectChange(option, "industry_id", "industry")}
                                                options={this.state.industries}
                                            />
                                            {this.state.industry.length > 0 && (
                                                <div className="permission-policy-list-wrapper">
                                                    {this.state.industry.map((item, i) => {
                                                        return (
                                                            <li key={i}> {item.name}{" "} <i className="fa fa-times-circle" onClick={() => this.handleMultiRemove(i, item.id, "industry")} ></i></li>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Research Type <span className='required'>*</span></label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.type_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.type_id}
                                                onChange={(option) => this.handleSelectChange(option, 'type_id')}
                                                options={this.state.types}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Country </label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.development_stageEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.location_id}
                                                onChange={(option) => this.handleSelectChange(option, 'location_id')}
                                                options={this.state.locations}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Price</label>
                                            <input title='number'
                                                className="form-control"
                                                name='price'
                                                value={this.state.formFields.price}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Published</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.is_published}
                                                onChange={(option) => this.handleSelectChange(option, 'is_published')}
                                                options={PUBLISHED}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Sponsor Logo</label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.sponsor_logo_id === '' ? 'Choose a File' : this.state.formFields.sponsor_logo_id}</div>
                                                    <input type='file' className="custom-file-input" name='sponsor_logo_id' onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('sponsor-logo', 'sponsor_logo_id', this.state.sponsor_logo_idFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.sponsor_logo_idMsg.length > 0 && <div className='error'>{this.state.sponsor_logo_idMsg}</div>}
                                            {this.state.sponsor_logo_idError && <div className='error'>Upload failed!</div>}
                                            {this.state.sponsor_logo_idSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.sponsor_logo_idUpload && <div className='error'>Sponsor Logo is chosen but not uploaded</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Language<span className='required'>*</span></label>
                                            <Select
                                                name='lang'
                                                placeholder='Please Select'
                                                styles={this.state.langEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.lang}
                                                onChange={(option) => this.handleSelectChange(option, 'lang')}
                                                options={LANGUAGES}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >URL</label>
                                            <input type="text"
                                                className={"form-control"}
                                                value={this.state.formFields.code}
                                                name='code'
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Featured</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.is_featured}
                                                onChange={(option) => this.handleSelectChange(option, 'is_featured')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label>Download form type</label>
                                            <Select
                                                placeholder='Select type'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.download_form_type}
                                                onChange={option => this.handleSelectChange(option, 'download_form_type')}
                                                options={RESEARCH_DOWNLOAD_FORM_TYPE_OPTIONS}
                                            />
                                        </div>
                                    </div>

                                    <hr />

                                    <h6>Table of Contents</h6>
                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label>Main Table of Contents</label>
                                            <p className="form-text text-muted mt-0">
                                                (Table of contents for this report)
                                            </p><p></p>
                                            <textarea className="form-control" rows='5' value={this.state.formFields.table_of_contents} name='table_of_contents' onChange={this.handleInputChange} />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label>Promoted Table of Contents</label>
                                            <p className="form-text text-muted mt-0">
                                                (Table of contents for a promoted report such as paid version of free report)
                                            </p>
                                            <textarea className="form-control" rows='5' value={this.state.formFields.table_of_contents_promoted} name='table_of_contents_promoted' onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Promoted Research</label>
                                            <p className="form-text text-muted mt-0">
                                                (If using the field 'Promoted Table of Contents', select which report is being promoted here)
                                            </p>
                                            <AsyncSelect
                                                placeholder='Enter Search Keyword'
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.promoted_research_id}
                                                onChange={(option) => this.handleSelectChange(option, 'promoted_research_id')}
                                                cacheOptions
                                                loadOptions={this.loadPromotedResearches}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate('research')}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('research')}>Add Research</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(ResearchForm)
